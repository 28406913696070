import Title from '../Title';

function About() {
  return (
    <div>
      <Title title="About Us" route="about" />
      <h1>About Us</h1>
    </div>
  );
}

export default About;
